<template>
  <div class="statistik-blank text-center" style="margin-top: 75px">
    <h2 class="text-dark">
      <b> Mohon Ma'af </b>
    </h2>
    <span>Jadwal pengerjaan try out telah selesai. Silakan ikuti tryout lainnya</span> <br />
    <b-button class="mt-2" variant="danger" :to="{ name: 'tryout' }"> Kembali </b-button>
  </div>
</template>

<script>
import { BButton, BLink } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
    BLink,
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
